<script setup>
  import Field from './Field.vue'

  const props = defineProps({
    store: { type: Object, required: true }
  })

  const isValid = ref(false)
  const form = ref()

  const { currentItem } = storeToRefs(props.store)

  const validateForm = async () => {
    return await form.value.validate()
  }

  defineExpose({
    validateForm
  })
</script>

<template>
  <v-form ref="form" v-model="isValid" validate-on="input">
    <div v-for="field in store.fieldsFiltered" :key="field">
      <Field :field="field" :item="currentItem" :store="store" />
    </div>
  </v-form>
</template>
