<script setup></script>

<template>
  <v-layout class="center" style="height: 100%; width: 100%">
    <div class="text-center">
      <div
        style="
          font-family: Bahnschrift, serif;
          font-size: 148px;
          font-weight: lighter;
          height: 180px;
        "
      >
        404
      </div>
      <div style="font-family: Bahnschrift, serif; font-size: 24px">
        It seems like you are trying to access a page which does not exists
      </div>
      <div class="mt-6">
        <v-btn rounded="pill" append-icon="mdi-home" color="primary" :to="{ name: 'project-index' }"
          >Go home</v-btn
        >
      </div>
    </div>
  </v-layout>
</template>
