<script setup>
  import Form from '../form/Form.vue'

  const props = defineProps({
    name: { type: String, required: false },
    store: { type: Object, required: true }
  })
  const emit = defineEmits(['save'])
  const { createUpdateDialog, writeLoading, currentItem } = storeToRefs(props.store)
  const formRef = ref(null)
  const createOrUpdate = computed(() => {
    return currentItem.value['id']
      ? 'Update'
      : currentItem.value['duplicated_id']
      ? 'Duplicate'
      : 'Create'
  })

  const onSaveButtonClicked = async () => {
    const isValid = await formRef.value.validateForm()
    if (isValid.valid) {
      emit('save')
    }
  }
</script>
<template>
  <v-dialog v-model="createUpdateDialog" max-width="400">
    <v-card>
      <v-card-title class="pt-6">{{ createOrUpdate }} {{ name }}</v-card-title>
      <v-card-text>
        <Form :store="store" ref="formRef" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" variant="text" @click="store.toggleCreateUpdateDialog()">
          Cancel
        </v-btn>
        <v-btn
          :loading="writeLoading"
          color="primary"
          variant="text"
          @click="onSaveButtonClicked()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
