import { config } from '../backend.config'

import { useDjango } from './useDjango'

export const useApi = () => {
  const { api } = useDjango()
  return { api }
}

export const idField = config.backendConfig === 'postgres' ? 'id' : '_id'
