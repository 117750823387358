import { h } from 'vue'
import speckleLogoIcon from '@/components/icons/SpeckleLogoIcon.vue'

const customSvgNameToComponent = {
  speckleLogoIcon: speckleLogoIcon
}

const customSVGs = {
  component: (props) => h(customSvgNameToComponent[props.icon])
}

export { customSVGs /* aliases */ }
