export const useSnackbarStore = defineStore('snackbar', {
  state: () => ({
    active: false,
    message: '',
    color: 'primary',
    statusCode: null,
    timeout: 5000
  }),
  actions: {
    parseErrorMessage(errorData) {
      if (typeof errorData === 'string') {
        return errorData
      }
      if (typeof errorData !== 'object') {
        return 'Error occurred!'
      }

      const errArr: string[] = []
      for (const [key, value] of Object.entries(errorData)) {
        if (Array.isArray(value)) {
          if (typeof value[0] === 'string') {
            errArr.push(`${key}: ${value[0]}`)
          } else {
            for (const [_key, _value] of Object.entries(value[0])) {
              errArr.push(`${_key}: ${_value}`)
            }
          }
        } else if (typeof value === 'string') {
          errArr.push(`${key}: ${value}`)
        }
      }
      return errArr.join('; ')
    },
    parseSuccessMessage(status) {
      switch (status) {
        case 201:
          return 'Item created successfully!'
        case 202:
          return 'Accepted!'
        case 204:
          return 'Item deleted successfully!'
        default:
          return 'Item updated successfully!'
      }
    },
    setMessage(message, color = 'secondary', statusCode, timeout = 5000) {
      switch (color) {
        case 'error':
          this.message = this.parseErrorMessage(message)
          break
        case 'primary':
          this.message = this.parseSuccessMessage(statusCode)
          break
        default:
          this.message = message
      }
      this.color = color
      this.timeout = timeout
      this.active = true
      this.statusCode = statusCode
    }
  }
})
