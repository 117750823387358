import {
  ApiServiceHandler,
  EventBusInterceptor,
  UseService
} from '@rhdhv/js-api-authentication-management'
import API from '@/api/apiUrls'
import { useEventsBus } from '@rhdhv/vue-component-library'

const { emit } = useEventsBus()
const interceptor = new EventBusInterceptor(emit)

const baseURL = import.meta.env.VITE_APP_API_BASE_URL

const projectApiServiceHandler = new ApiServiceHandler(
  baseURL,
  API.API.TOKEN.INDEX,
  API.API.TOKEN.REFRESH,
  interceptor
)
export const useDjango = () => {
  const api = new UseService(projectApiServiceHandler)
  return { api }
}
