import AppBaseStore from './baseStore'
import { BaseStoreActions, BaseStoreType } from '@/types/storeTypes'
import type { Role } from '@/stores/type/type'
import API from '@/api/apiUrls'
import { useDjango } from '@/composables/useDjango'

const { api } = useDjango()

type State = BaseStoreType<Role>

const baseUrl = API.ROLES.INDEX
const baseStore: BaseStoreType<Role> = new AppBaseStore(baseUrl, api)
const baseStoreActions: BaseStoreActions<Role> = baseStore.actions

export const useAuthorizationStore = defineStore({
  id: 'authorization',
  state: (): State => ({
    ...baseStore.state
  }),
  actions: {
    ...baseStoreActions,
    setCurrentItem(roleIds) {
      roleIds.forEach((roleId) => {
        const role = this.getCurrentRole(roleId)
        this.currentItem[role.auth_source] = role.permissions
      })
    },
    getCurrentRole(roleId) {
      return this.items.find((item) => item.id === roleId)
    },
    hasServicePermission({ authSource, allowed }) {
      if (typeof allowed === 'string') {
        allowed = [allowed]
      }
      return this.currentItem[authSource]?.some((permission) => allowed.includes(permission))
    },
    hasItemPermission({ authInstance, allowed }) {
      return authInstance.role.permissions.some((permission) => allowed.includes(permission))
    },
    resetState() {
      this.items = []
      this.currentItem = {}
    }
  },
  getters: {
    ...baseStore.getters
  }
})
