<script setup>
  const props = defineProps({
    text: { type: String, required: true },
    size: { type: String, required: false, default: 'small' },
    color: { type: String, required: false, default: '#757575' },
    vClass: { type: String, required: false },
    maxWidth: { type: String, required: false, default: '500px' },
    location: { type: String, required: false, default: 'bottom' }
  })
</script>
<template>
  <v-tooltip :location="props.location" :max-width="props.maxWidth">
    <template v-slot:activator="{ props }">
      <v-icon v-bind="props" :color="props.color" :class="props.vClass" :size="props.size">
        mdi-help-circle</v-icon
      >
    </template>
    <span>{{ props.text }}</span>
  </v-tooltip>
</template>
