import AppBaseStore from './baseStore'
import { useDjango } from '@/composables/useDjango'
import type { BaseStoreActions, BaseStoreType, Project } from '@/types/storeTypes'
import type { GraphSideBar } from '@/stores/type/project.type'
import { DrawerType } from '@/stores/type/project.type'
import API from '@/api/apiUrls'
import { useLocalStorage } from '@vueuse/core'

const { api } = useDjango()

type State = BaseStoreType<Project>

const baseUrl = API.CORE.PROJECTS.INDEX
const baseStore: BaseStoreType<Project> = new AppBaseStore(baseUrl, api)
const baseStoreActions: BaseStoreActions<Project> = baseStore.actions
export const DEFAULT_DATA_MANAGEMENT_DRAWER_WIDTH = 700
export const DEFAULT_GRAPH_BAR_HEIGHT = 400
export const DEFAULT_GRAPH_BAR_WIDTH = 1000
export const useProjectStore = defineStore({
  id: 'projects',
  state: (): State => ({
    ...baseStore.state,
    filterFields: ['work_order', 'name', 'location', 'client', 'description'],
    drawer: {
      shown: useLocalStorage('gtlp-drawer-shown', true),
      width: 250
    },
    profileDrawer: {
      shown: false,
      width: DEFAULT_DATA_MANAGEMENT_DRAWER_WIDTH,
      minWidth: 300
    },
    rasterDataDrawer: {
      shown: false,
      width: DEFAULT_DATA_MANAGEMENT_DRAWER_WIDTH,
      minWidth: 300
    },
    speckleDrawer: {
      shown: false,
      width: DEFAULT_DATA_MANAGEMENT_DRAWER_WIDTH,
      minWidth: 300
    },
    groundInvestigationDrawer: {
      shown: false,
      width: DEFAULT_DATA_MANAGEMENT_DRAWER_WIDTH,
      minWidth: 300
    },
    userManagementDrawer: {
      shown: false,
      width: DEFAULT_DATA_MANAGEMENT_DRAWER_WIDTH,
      minWidth: 300
    },
    lengthProfileGraphBar: {
      shown: false,
      height: DEFAULT_GRAPH_BAR_HEIGHT,
      width: DEFAULT_GRAPH_BAR_WIDTH,
      minWidth: 400,
      minHeight: 200,
      maxWidth: 1500
    },
    projectLoading: false
  }),
  actions: {
    ...baseStoreActions,
    resetAll() {
      this.$reset()
    },
    resetGraphSize(graph: GraphSideBar) {
      graph.height = DEFAULT_GRAPH_BAR_HEIGHT
      graph.width = DEFAULT_GRAPH_BAR_WIDTH
    },
    toggleDrawer() {
      this.drawer.shown = !this.drawer.shown
      useLocalStorage('gtlp-drawer-shown', this.drawer.shown)
    },
    toggleDrawerType(drawerType: DrawerType, forceShow: boolean = false) {
      const drawerTypes: DrawerType[] = [
        'profileDrawer',
        'rasterDataDrawer',
        'groundInvestigationDrawer',
        'userManagementDrawer',
        'speckleDrawer'
      ]
      for (const type of drawerTypes) {
        this[type].width = DEFAULT_DATA_MANAGEMENT_DRAWER_WIDTH
        if (type === drawerType) {
          this[type].shown = forceShow || !this[type].shown
        } else {
          this[type].shown = false
        }
      }
    },
    toggleGraphType() {
      this.lengthProfileGraphBar.shown = !this.lengthProfileGraphBar.shown
      this.profileDrawer.width = this.lengthProfileGraphBar.shown
        ? 400
        : DEFAULT_DATA_MANAGEMENT_DRAWER_WIDTH
    },
    async fetchUploadUrl(blobName) {
      const query_params = new URLSearchParams({
        blob_name: blobName
      })
      const url = API.CORE.PROJECTS.GET_UPLOAD_URI + '?' + query_params.toString()
      const response = await api.apiService.api(url, 'GET')
      return response.url
    },
    setProjectLoading(loadingState: boolean) {
      this.projectLoading = loadingState
    }
  },
  getters: {
    ...baseStore.getters,
    getCurrentProject(state) {
      return state.currentItem ? state.currentItem : null
    },
    isLengthProfileGraphBarShown(state) {
      return state.lengthProfileGraphBar.shown
    }
  }
})
