import BaseStore from '@rhdhv/vue-basestore'
import _ from 'lodash'

class AppBaseStore extends BaseStore {
  constructor(r: string, a: any, l = null, i: string = 'id') {
    super(r, a, l, i)
    this.state = {
      ...this.state,
      error: false,
      options: {},
      a: a,
      r: r,
      i: i
    }
    this.actions = {
      ...this.actions,
      fetchOptions: this.fetchOptions,
      postOrUpdate: this.postOrUpdate,
      update: this.update,
      setDeleteItem: this.setDeleteItem
    }
    this.getters = {
      ...this.getters,
      fieldsList(state) {
        if (state.options.actions) {
          const writableFields = Object.entries(state.options.actions.POST)?.filter(
            ([, value]: [string, any]) => !value.read_only
          )
          return writableFields?.map((item: any) => {
            item[1].parameter = item[0]
            return item[1]
          })
        } else {
          return []
        }
      },
      fieldsFiltered(state) {
        return this.fieldsList?.filter((field) => state.filterFields.includes(field.parameter))
      },
      fields(state) {
        return state.options.actions?.POST
      },
      itemsAvailable(state) {
        return state.items ? state.items.length > 0 : false
      },
      optionsAvailable(state) {
        return !_.isEmpty(state.options)
      }
    }
  }

  async fetchOptions() {
    this.options = await this.a.service(this.r).options()
  }

  async postOrUpdate(item = null) {
    return this.currentItem.id
      ? await this.update(item, this.currentItem.id)
      : await this.create(item)
  }

  async update(item = null, id = null) {
    this.writeLoading = true
    const updateItem = item ? item : this.currentItem
    let responseData
    const idNumber = id ? id : updateItem[this.i]
    try {
      responseData = await this.a.service(this.r).patch(idNumber, updateItem)
      const index = this.items.map((item) => item[this.i]).indexOf(responseData[this.i])
      this.items[index] = responseData
    } catch (e) {
      this.writeLoading = false
      return false
    }
    this.writeLoading = false
    return responseData
  }

  setDeleteItem(e) {
    this.deleteItem = e
  }
}

export default AppBaseStore
