import { createRouter, createWebHistory } from 'vue-router'
import IndexView from '../views/projects/ProjectIndexView.vue'
import NotFoundView from '../views/NotFoundView.vue'
import { authRoutes } from '@rhdhv/vue-authentication-middleware'
import { useEventsBus } from '@rhdhv/vue-component-library'

const { emit } = useEventsBus()

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/:pathMatch(.*)*',
      component: NotFoundView
    },
    {
      path: '/',
      name: 'home',
      redirect: {
        name: 'auth-login'
      }
    },
    authRoutes,
    {
      path: '/project-index',
      name: 'project-index',
      component: IndexView,
      beforeEnter: () => {
        emit('authenticateSpeckle')
        const store = useProjectStore()
        store.resetAll()
        return true
      }
    },
    {
      path: '/projects/:id',
      name: 'project-detail',
      component: () => import('../views/projects/ProjectDetailView.vue')
    }
  ]
})

router.beforeEach(async (to) => {
  const authStore = inject('useAuthStore')
  const { user } = storeToRefs(authStore)

  if (!user?.value?.id) {
    const userResponse = await authStore.reAuthenticate()
    if (userResponse) {
      authStore.$patch({
        user: userResponse.user
      })
    }
  }
  if (to.name.startsWith('auth-')) {
    if (user.value) {
      return { name: 'project-index' }
    }
    return true
  }
  // check auth and apply login redirect
  if (!user.value) {
    authStore.loginRedirect = to
    return { name: 'auth-login' }
  }
  return true
})

export default router
