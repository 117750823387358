import API from '@/api/apiUrls'

const { api } = useApi()

// TODO transfer to typescript
export const useDjangoAuthStore = defineStore('auth', {
  id: 'auth',
  state: () => ({
    user: null,
    isInitDone: true
  }),
  actions: {
    async authenticate({ email, password }) {
      await api.apiService.authenticationHandler.authenticate(email, password)
      const userResponse = await api.service(API.CORE.USERS.CURRENT_USER).get()
      return { user: userResponse }
    },
    async reAuthenticate() {
      await api.apiService.authenticationHandler.reauthenticate()
      try {
        const userResponse = await api.service(API.CORE.USERS.CURRENT_USER).get()
        return { user: userResponse }
      } catch (e) {
        return { user: null }
      }
    },
    async getPromise() {},
    async logout() {
      localStorage.removeItem('access')
      localStorage.removeItem('refresh')
      this.user = null
    }
  }
})
