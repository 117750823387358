import AppBaseStore from './baseStore'
import { useDjango } from '@/composables/useDjango'
import type { BaseStoreActions, BaseStoreType } from '@/types/storeTypes'
import API from '@/api/apiUrls'
import type { ProjectUser } from '@/stores/type/projectUser.type'
import { AuthModelUserBaseStore } from '@rhdhv/vue-user-management'

const { api } = useDjango()

type State = BaseStoreType<ProjectUser>

const baseUrl = API.CORE.PROJECT_USERS.INDEX
const baseStore: BaseStoreType<ProjectUser> = new AppBaseStore(baseUrl, api)
const authModelUserBaseStore = new AuthModelUserBaseStore()
const baseStoreActions: BaseStoreActions<ProjectUser> = baseStore.actions

export const useProjectUserStore = defineStore({
  id: 'projectUsers',
  state: (): State => ({
    ...baseStore.state,
    ...authModelUserBaseStore.state
  }),
  actions: {
    ...baseStoreActions,
    ...authModelUserBaseStore.actions,
    resetState() {
      this.items = []
      this.currentItem = {}
    }
  },
  getters: {
    ...baseStore.getters,
    ...authModelUserBaseStore.getters
  }
})
