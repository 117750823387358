<script setup lang="ts">
  import CreateOrUpdateDialog from '@/components/dialog/CreateOrUpdateDialog.vue'
  import DeleteDialog from '@/components/dialog/DeleteDialog.vue'
  import { onMounted, onUnmounted, ref, watch } from 'vue'
  import { storeToRefs } from 'pinia/dist/pinia'
  import { useProjectStore } from '@/stores/project'
  import { useAuthorizationStore } from '@/stores/authorization'
  import { useStorage } from '@vueuse/core'
  import {
    ProjectIndexTitle,
    ProjectIndex,
    ProjectDataTable,
    ProjectHeaderImage
  } from '@rhdhv/vue-component-library'
  import router from '@/router'
  import { useDjangoAuthStore } from '@/stores/authDjango'
  import type { Project } from '@/types/storeTypes'

  const authorizationStore = useAuthorizationStore()
  const store = useProjectStore()
  const projectUserStore = useProjectUserStore()
  const authStore = useDjangoAuthStore()
  const { items: roles } = storeToRefs(authorizationStore)
  const { user: currentUser } = storeToRefs(authStore)

  const {
    items,
    createUpdateDialog,
    deleteDialog,
    deleteItem,
    writeLoading: loading
  } = storeToRefs(store)
  const viewMode = useStorage('projectIndexViewMode', 'grid')
  const search = ref('')
  const canEditProject = ref(false)
  const canDeleteProject = ref(false)
  watch(createUpdateDialog, () => {
    if (!createUpdateDialog.value) {
      store.setCurrentItem({})
    }
  })

  watch(deleteDialog, () => {
    if (!deleteDialog.value) {
      store.setDeleteItem({})
    }
  })

  async function postOrUpdate() {
    const response = await store.postOrUpdate()
    if (response) {
      store.toggleCreateUpdateDialog()
    }
  }

  const updatePermissions = async (projects: Project[]) => {
    for (const item of projects) {
      const response = await projectUserStore.fetchItems({ search: item.id })
      const currentProjectUser = response.find(
        (response: any) => response.user === currentUser.value?.id
      )
      const currentRole = roles.value.find((role) => role.id === currentProjectUser?.role)
      canEditProject.value = currentRole?.permissions?.includes('change_project') || false
      canDeleteProject.value = currentRole?.permissions?.includes('delete_project') || false
    }
  }
  onMounted(async () => {
    if (!items || !items.value.length) {
      await store.fetchOptions()
      await store.fetchItems({})
    }
    await authorizationStore.fetchItems({})
    if (items.value.length) {
      await updatePermissions(items.value)
    }
  })

  onUnmounted(() => {
    projectUserStore.resetState()
    authorizationStore.resetState()
  })

  const handleSearchValue = (value: string) => {
    search.value = value
  }

  const navigateToProject = (projectID: number) => {
    router.push({ name: 'project-detail', params: { id: projectID } })
  }
</script>

<template>
  <div>
    <div class="mb-16">
      <ProjectHeaderImage :src="'/header.jpeg'" :lazy-src="'/header_lazy.jpg'" :loading="loading" />
      <v-row class="mt-2">
        <v-col cols="1" sm="0"></v-col>
        <v-col cols="10">
          <ProjectIndexTitle
            title="GEOProfile"
            :store="store"
            :loading="loading"
            @update-search="handleSearchValue"
          />
          <ProjectIndex
            v-if="viewMode === 'grid'"
            :search="search"
            :items="items"
            :can-edit="canEditProject"
            :can-delete="canDeleteProject"
            :loading="loading"
            @navigate-to-project="navigateToProject"
          />
          <ProjectDataTable
            v-else
            class="cursor-pointer py-8 px-2 custom-border"
            items-per-page="10"
            density="comfortable"
            :filter-keys="['work_order', 'name', 'location', 'client']"
            :items="items"
            :search="search"
            :can-edit="canEditProject"
            :can-delete="canDeleteProject"
            :store="store"
            @navigate-to-project="navigateToProject"
            @delete-item="store.toggleDeleteDialog"
            @edit-item="(item) => store.toggleCreateUpdateDialog(JSON.parse(JSON.stringify(item)))"
          />
        </v-col>
        <v-col cols="1" sm="0"></v-col>
      </v-row>
    </div>
    <CreateOrUpdateDialog
      :store="store"
      :name="'project'"
      @save="postOrUpdate()"
    ></CreateOrUpdateDialog>
    <DeleteDialog :store="store" :name="deleteItem?.name" @delete="store.delete()"></DeleteDialog>
  </div>
</template>

<style scoped>
  :deep(.v-table__wrapper table) {
    border: 1px solid lightgrey;
  }
</style>
